:root {
  --extra-light-grey: #fafafa;
  --light-grey: #c4c4c4;
  --dark-grey: #4f4f4f;
  --grey: #bbbbbb;
  --black: #000000;
  --dark-background: #121212;
  --dark-background-lighter: #191919;
  --dark-grey-medium: #6f6f6f;
  --white: #ffffff;
  --neutrals-light: #f1f1f1;
  --red: #ff0000;
  --red-light: #ed1c24;
  --red-lighter: #ed301c;
  --red-lightest: #ffcccb;
  --orange: #f26528;
  --white-alpha: rgba(255, 255, 255, 0.1);
  --shadow-grey: rgba(25, 25, 25, 0.1);
  --shadow-grey-darker: rgba(25, 25, 25, 0.85);
  --shadow-light-grey: rgba(25, 25, 25, 0.05);
  --red-alpha-1: rgba(237, 28, 36, 0.1);
  --green: #a3cb38;
}

// Material Icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Foundation Core (Everything)
@import '../../../../../node_modules/foundation-sites/scss/foundation';

// Import Foundation components (custom modified in /assets/foundation/ folder)
@import '../../assets/scss/foundation/global';

// Settings (Custom)
@import '../../assets/scss/foundation/settings';

@include foundation-prototype-classes;
@include foundation-flex-classes;
@include foundation-grid;
@include foundation-visibility-classes;
@include foundation-xy-grid-classes(
  $base-grid: true,
  $vertical-grid: true,
  $margin-grid: true,
  $padding-grid: false,
  $block-grid: false,
  $collapse: false,
  $offset: false,
  $frame-grid: false
);
@include foundation-table();

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url('/assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  font-style: normal;
  src: url('/assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: italic;
  src: url('/assets/fonts/Poppins-Italic.ttf');
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-image: url('/assets/img/login-background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

html,
body {
  height: 100%;
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  word-break: break-word;
}

p {
  max-width: 700px;
}

h1 {
  font-size: 28px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 12px;
  font-weight: 500;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-dark {
  font-weight: 600;
}

.fs-l {
  font-size: 16px;
}

small,
.small {
  font-size: 10px;
}

.fs-8-vw {
  font-size: 0.8vw;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

a {
  color: var(--black);
  text-decoration: underline;
  cursor: pointer;

  &.no-underline {
    text-decoration: none;
  }
}

.t-d-ul {
  text-decoration: underline;
}

.t-a-r {
  text-align: right;
}

.t-a-c {
  text-align: center;
}

p {
  &.placeholder {
    color: var(--grey);
  }

  &.fs-i {
    font-style: italic;
  }
}

.no-underline {
  text-underline: none;
}

.c-dark-grey {
  color: var(--dark-grey);
}

.bc-light-grey {
  background-color: var(--extra-light-grey);
}

.c-grey {
  color: var(--grey);
}

.c-dark-grey-m {
  color: var(--dark-grey-medium);
}

.c-dark-grey {
  color: var(--dark-grey);
}

.c-red {
  color: var(--red-light);
}

.c-orange {
  color: var(--orange);
}

.c-white {
  color: var(--white);
}

.pr-0 {
  padding-right: 0px;
}

.pr-xxs {
  padding-right: 4px;
}

.pr-xs {
  padding-right: 8px;
}

.pr-s {
  padding-right: 16px;
}

.pr-m {
  padding-right: 24px;
}

.pr-l {
  padding-right: 32px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-xxs {
  padding-left: 4px;
}

.pl-xs {
  padding-left: 8px;
}

.pl-s {
  padding-left: 16px;
}

.pl-m {
  padding-left: 24px;
}

.pl-l {
  padding-left: 32px;
}

.pl-xl {
  padding-left: 36px;
}

.pt-xxs {
  padding-top: 4px;
}

.pt-xs {
  padding-top: 8px;
}

.pt-s {
  padding-top: 16px;
}

.pt-m {
  padding-top: 24px;
}

.pt-l {
  padding-top: 32px;
}

.pt-xl {
  padding-top: 48px;
}

.pb-xxs {
  padding-bottom: 4px;
}

.pb-xs {
  padding-bottom: 8px;
}

.pb-s {
  padding-bottom: 16px;
}

.mr-a {
  margin-right: auto;
}

.mr-xxs {
  margin-right: 4px;
}

.mr-xs {
  margin-right: 8px;
}

.mr-s {
  margin-right: 16px;
}

.mr-m {
  margin-right: 24px;
}

.mr-l {
  margin-right: 32px;
}

.ml-a {
  margin-left: auto;
}

.ml-xxs {
  margin-left: 4px;
}

.ml-xs {
  margin-left: 8px;
}

.ml-s {
  margin-left: 16px;
}

.ml-m {
  margin-left: 24px;
}

.ml-l {
  margin-left: 32px;
}

.mt-xxs {
  margin-top: 4px;
}

.mt-xs {
  margin-top: 8px;
}

.mt-s {
  margin-top: 16px;
}

.mt-m {
  margin-top: 24px;
}

.mt-l {
  margin-top: 32px;
}

.mt-xl {
  margin-top: 48px;
}

.mb-xxs {
  margin-bottom: 4px;
}

.mb-xs {
  margin-bottom: 8px;
}

.mb-s {
  margin-bottom: 16px;
}

.mb-m {
  margin-bottom: 24px;
}

.mb-l {
  margin-bottom: 32px;
}

.mb-xl {
  margin-bottom: 48px;
}

.plain-line {
  height: 2px;
  width: 36px;
}

.square-xs {
  height: 8px;
  width: 8px;
}

.square-12 {
  height: 12px;
  width: 12px;
}

.square-s {
  height: 16px;
  width: 16px;
}

.square-m {
  height: 24px;
  width: 24px;
}

.square-32 {
  height: 32px;
  width: 32px;
}

.square-l {
  height: 40px;
  width: 40px;
}

.square-xxl {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;

  img {
    max-height: 56px;
    max-width: 56px;
  }
}

.t-t-uc {
  text-transform: uppercase;
}

button,
label.upload-button {
  &,
  &.mat-menu-item {
    display: flex;
    align-items: center;
    line-height: unset;
  }

  &:not(.mat-menu-item) {
    justify-content: center;
  }

  &.light {
    background-color: var(--neutrals-light);
  }

  &.only-border {
    background-color: transparent;
    color: var(--dark-grey);
    border: solid 1px var(--dark-grey);
    padding: 16px 24px;
  }

  &:not(.icon-button):not(.mat-menu-item):not(.only-border):not(.no-img-padding) {
    padding: 4px 16px;
    height: 40px;

    img {
      padding: 12px;
      margin-right: 4px;
    }
  }

  &.icon-button {
    height: 48px;
    width: 48px;

    &:not(.not-colored) {
      background-color: var(--neutrals-light);
    }

    &.red {
      background-color: var(--red-alpha-1);
    }
  }

  &.mat-menu-item {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    padding: 8px 56px 8px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--neutrals-light);
    }

    img {
      padding: 8px;
    }
  }

  &.small {
    padding: 8px 16px !important;
    height: 33.5px !important;
    font-size: 12px;
  }

  &.grey-hover {
    &:hover {
      filter: brightness(85%);
    }
  }
}

.mat-menu-panel {
  border-radius: 0 !important;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }
}

input,
button,
textarea {
  border: none;
  outline: none;
}

.z-i-1 {
  z-index: 1;
}

.z-i-2 {
  z-index: 2;
}

.search-input-container {
  background-color: var(--dark-grey);
  border-radius: 40px;
  height: 40px;
  width: 240px;
  padding-left: 40px;

  input {
    background-color: var(--dark-grey);
    font-size: 12px;
    color: var(--white);
    text-align: center;
  }

  button {
    padding-left: 4px;
    padding-right: 20px;
  }
}

label {
  cursor: pointer;
}

input[type='file'] {
  display: none;
}

.input-container {
  label {
    color: var(--black);
  }

  &:not(.number-input) {
    input,
    textarea,
    mat-select {
      height: 40px;
      padding: 8px 8px 8px 8px;
      border-bottom: 1px solid var(--grey);
      border-radius: 0;
      font-size: 12px;
      z-index: 1;
      background: transparent;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: 100% !important;
    padding-right: 42px;
  }

  img {
    right: 8px;
    top: 37px;

    &.no-label {
      top: 12px;
    }
  }

  &.textarea-container {
    img {
      right: 20px;
    }
  }

  &:disabled,
  &.disabled {
    background: var(--shadow-light-grey);

    label {
      color: var(--grey);
    }
  }

  input,
  textarea,
  mat-select {
    &:disabled,
    &.disabled {
      cursor: not-allowed;
    }
  }

  &.search-field {
    input,
    textarea {
      border-bottom: none;
      text-align: center;
    }

    .search-placeholder {
      position: absolute;
      top: 11px;
    }
  }

  mat-select {
    .mat-select-arrow {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      height: 16px;
      width: 16px;
      background-image: url('/assets/img/arrow-down.svg');
    }
  }

  &.header-dropdown {
    mat-select {
      display: flex;
      align-items: center;
      border-bottom: none;
      font-size: 16px;
      font-weight: bold;

      .mat-select-arrow {
        background-image: url('/assets/img/arrow-down-filled.svg');
      }

      .mat-select-value {
        max-width: unset;
      }
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    box-shadow: 0 0 10px 0 var(--shadow-grey);

    mat-option.empty-value {
      font-style: italic;
    }
  }
}

.mat-select-panel.header-dropdown {
  font-weight: 500;
  font-size: 14px;
  margin: 32px 0 0 8px;

  .mat-option {
    padding: 0 8px;
  }
}

.bg-n-light {
  background-color: var(--neutrals-light);
}

.bg-dark {
  background-color: var(--dark-background);

  &:disabled {
    background: var(--grey);
  }
}

.bg-white {
  background: var(--white);
}

.bg-extra-light-grey {
  background: var(--extra-light-grey);
}

.bg-light-red {
  background: var(--red-lightest);
}

.bg-red {
  background: var(--red-light);
}

.b-red {
  border: solid 2px var(--red-light);
}

.bs-b10-grey {
  box-shadow: 0 0 10px 0 var(--shadow-grey);
}

.bs-b5-light-grey {
  box-shadow: 0 0 5px 0 var(--shadow-light-grey);
}

.bt-2-red {
  border-top: solid 2px var(--red-light);
}

.bt-2-dark {
  border-top: solid 2px var(--dark-background);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--dark-background);
  opacity: 0.75; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--white);
}

.disp-f {
  display: flex;

  &.f-dir-c {
    flex-direction: column;
  }

  &.a-i-c {
    align-items: center;
  }

  &.j-c-c {
    justify-content: center;
  }

  &.j-c-fe {
    justify-content: flex-end;
  }

  &.j-c-sb {
    justify-content: space-between;
  }

  &.j-c-sa {
    justify-content: space-around;
  }

  &.f-w-wrap {
    flex-wrap: wrap;
  }
}

.disp-b {
  display: block;
}

.disp-n {
  display: none;
}

.rotate-90 {
  transform: rotate(90deg);
}

button,
.c-p {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.c-a {
  cursor: auto;
}

.c-na {
  cursor: not-allowed;
}

.c-t {
  cursor: text;
}

.ws-nowrap {
  white-space: nowrap;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scroll-vertical {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

table.ledsreact-table {
  &:not(.simple-table) {
    thead {
      tr {
        th:not(:first-of-type):not(:last-of-type) {
          border-bottom: 1px solid var(--neutrals-light);
        }

        th.action-column {
          width: 32px;
        }

        th.small-column {
          width: 150px;
        }

        th.x-small-column {
          width: 70px;
        }

        th:first-of-type,
        th:last-of-type {
          width: 36px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 8px solid var(--extra-light-grey);
      padding: 4px 0;
      width: 44px;

      td.action-column {
        width: 32px;
        padding: 0;
      }

      td:first-of-type,
      td:last-of-type {
        width: 36px;
      }

      td {
        button.icon-button {
          background-color: transparent;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

tr.selected:not(.attempt-selected) {
  td {
    background-color: var(--neutrals-light);
  }
}

.bb-red-2 {
  border-bottom: 2px solid var(--red);
}

.bb-light-grey {
  border-bottom: 1px solid var(--neutrals-light);
}

.bb-grey {
  border-bottom: 1px solid var(--grey);
}

.bt-light-grey {
  border-top: 1px solid var(--neutrals-light);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.modal-container {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      min-width: 430px;
      max-width: 560px;
      max-height: 30vh;
    }
  }

  &.breakpoint-modal {
    .mat-dialog-content {
      min-width: 0 !important;
    }
  }

  &.select-modal {
    .mat-dialog-content {
      height: 250px;
    }
  }

  &.high-modal {
    .mat-dialog-content {
      height: 60vh;
    }
  }

  &.date-modal {
    .mat-dialog-content {
      max-height: 55vh;
    }
  }
}

.cdk-overlay-backdrop.breakpoint-modal {
  background: var(--shadow-grey-darker);
}

.mat-menu-panel {
  min-height: 0 !important;
}

.traject-selected {
  background-color: var(--red-alpha-1);
}

input[type='checkbox'] {
  display: none;
  opacity: 0;
}

input[type='checkbox'] + label {
  position: relative;
  padding: 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

input[type='checkbox'] + label:before {
  content: '';
  height: 24px;
  width: 24px;
  margin: 0 8px 0 0;
  background-color: var(--neutrals-light);
  border-radius: 2px;
  border: none;
  display: inline-block;
  flex-shrink: 0;
}

input[type='checkbox']:checked + label:before {
  &:not(.no-bg) {
    background-color: var(--white);
  }
}

input[type='checkbox']:checked + label:after {
  content: '';
  position: absolute;
  display: inline-block;
  align-self: center;
  background-image: url('/assets/img/check-red.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}

input[type='checkbox'] + label {
  cursor: pointer;
}

input[type='checkbox']:disabled + label {
  cursor: not-allowed;
  pointer-events: none;
}

input[type='checkbox']:disabled + label:before {
  background: var(--white);
}

input[type='radio'] {
  display: none;
  opacity: 0;
}

input[type='radio'] + label {
  position: relative;
  padding: 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type='radio'] + label:before {
  content: '';
  height: 24px;
  width: 24px;
  margin: 0 8px 0 0;
  display: inline-block;
  flex-shrink: 0;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid var(--dark-grey);
}

input[type='radio']:checked + label:before {
  border: none;
  background: linear-gradient(to right, var(--red-lighter), var(--orange));
}

input[type='radio']:checked + label:after {
  content: '';
  left: 1px;
  top: 1px;
  position: absolute;
  display: inline-block;
  align-self: center;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  box-sizing: border-box;
  border: 3px solid var(--white);
  background: var(--orange-gradient);
}

input[type='radio']:disabled + label {
  cursor: not-allowed;
  pointer-events: none;
}

input[type='radio']:disabled + label:before {
  border: 1px solid var(--grey);
}

input[type='radio']:checked:disabled + label:before,
input[type='radio']:checked:disabled + label:after {
  background: var(--grey);
}

.tooltip {
  position: absolute;
  top: -2px;
  left: 24px;
  padding: 8px 16px;
  box-shadow: 0 0 5px 0 rgba(25, 25, 25, 0.05);
  background-color: var(--white);
  z-index: 1;
  max-width: 250px !important;
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;

  &.b-0 {
    bottom: 0;
  }

  &.l-0 {
    left: 0;
  }

  &.r-0 {
    right: 0;
  }

  &.t-8 {
    top: 8px;
  }

  &.l-8 {
    left: 8px;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--dark-grey);
}

.mat-snack-bar-container.snack-bar {
  background: var(--dark-grey);
  border-radius: 0;
  box-shadow: none;
  min-width: 0;
  max-width: 50vw;
  color: var(--white);

  h5 {
    max-width: calc(100% - 49px);
  }

  .black-cross {
    display: none;
  }

  &.error {
    background: var(--red-light);
  }

  &.success {
    background: var(--green);
    color: var(--dark-background);

    .white-cross {
      display: none;
    }

    .black-cross {
      display: block;
    }
  }
}

.bl-dark-lighter {
  border-left: 1px solid var(--dark-background-lighter);
}

.bl-red-2 {
  border-left: 2px solid var(--red);
}

.bl-dark-2 {
  border-left: 2px solid var(--dark-background);
}

.br-dark-lighter {
  border-right: 1px solid var(--dark-background-lighter);
}

.bs-cb {
  box-sizing: content-box;
}

.wb-bw {
  word-break: break-word;
}

.highcharts-tooltip {
  span {
    display: flex;

    .custom-tooltip {
      min-width: 224px;
      width: 224px;
      display: block;
    }
  }
}

mat-calendar {
  .mat-calendar-header {
    .mat-calendar-controls {
      align-items: center;
      justify-content: space-between;
    }

    .mat-calendar-previous-button {
      background-image: url('/assets/img/arrow-left.svg');
      order: 1;

      &:disabled {
        background-image: url('/assets/img/arrow-left-grey.svg');
      }
    }

    .mat-calendar-period-button {
      display: flex;
      align-items: center;
      order: 2;
      border-radius: 0;
    }

    .mat-calendar-next-button {
      background-image: url('/assets/img/arrow-right.svg');
      order: 3;

      &:disabled {
        background-image: url('/assets/img/arrow-right-grey.svg');
      }
    }

    .mat-calendar-previous-button,
    .mat-calendar-next-button {
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 0;

      &:disabled {
        cursor: not-allowed;
      }

      &:after {
        display: none;
      }
    }

    .mat-calendar-spacer {
      display: none;
    }
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-calendar-body-cell.mat-calendar-body-in-range
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-calendar-body-in-range::before {
    background: var(--neutrals-light);
  }

  .mat-calendar-body-cell.mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: none;
  }

  .mat-calendar-body-selected {
    background: var(--dark-background);
  }

  .mat-calendar-body-today {
    border: none;
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-table {
    .mat-calendar-table-header-divider:after {
      display: none;
    }

    .mat-calendar-body-cell,
    .mat-calendar-body-cell:after,
    .mat-calendar-body-cell:before,
    .mat-calendar-body-cell-content,
    .mat-calendar-body-cell-preview {
      border-radius: 0;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;

      &:active {
        border: none;
      }
    }

    thead {
      background: white;

      th {
        height: 54px;
        width: 54px;
        padding: 0;
        border-bottom: none !important;
      }

      tr:not(:first-of-type) {
        display: none;
      }
    }

    tbody {
      tr {
        width: 54px;
        background: white;
        border-bottom: none;
      }

      td {
        height: 54px !important;
        width: 54px !important;
      }

      .mat-calendar-body-label {
        content: '';
      }
    }
  }
}

.mat-slide-toggle {
  width: 100%;

  .mat-slide-toggle-ripple {
    display: none;
  }

  .mat-slide-toggle-content {
    font-family: 'Poppins', sans-serif;
  }

  .mat-slide-toggle-label {
    width: 100%;
    justify-content: space-between;

    .mat-slide-toggle-bar {
      width: 40px;
      height: 24px;
      border-radius: 16px;
      background: var(--grey);
      transition: none;

      .mat-slide-toggle-thumb-container {
        top: 2px;
        left: 2px;

        .mat-slide-toggle-thumb {
          box-shadow: none;
          background: white;
        }
      }
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background: linear-gradient(157deg, var(--red-lighter), var(--orange));
    }

    .mat-slide-toggle-thumb {
      background: white;
    }
  }
}

.mat-form-field {
  margin-top: 16px;
}

.footer-background {
  background: var(--dark-background);
}
